import React from 'react'
import App from '../components/paypal'
import { Link } from 'gatsby';
import Layout from '../components/layout';



function PaymentDetails() {
     const selectedPrice = typeof window !== 'undefined' ? sessionStorage.getItem("selectedPrice") : null
     const MembershipCategory = typeof window !== 'undefined' ? sessionStorage.getItem("MembershipCategory") : null
     const getNewMembers = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem("NewMemberData")) : null;
     //console.log({getNewMembers})
     const getReturningMembers = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem("returningMemberData")) : null;
     const workshop =  typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem("WorkShop")) : null;
     //console.log({ getReturningMembers })
     //typeof window !== 'undefined' ? sessionStorage.clear() : null
    return (
      <Layout>
        <div className="card">
          <h5>{MembershipCategory}</h5>
          <br />
          <span style={{ fontSize: '25px' }}>Price :</span>
          <h5>$ {selectedPrice}</h5>
          <br />
          <App value={selectedPrice} newMember ={getNewMembers} returningMember={getReturningMembers} createWorkshop= {workshop}/>
          <hr />
          <Link to="/">
            {' '}
            <span style={{ fontSize: '25px' }}>←</span> Back to Home page
          </Link>
        </div>
      </Layout>
    )
}

export default PaymentDetails

