import React from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
const axios = require('axios')
import Swal from "sweetalert2";
import { AXIOSLINK } from "../utils/constants";
import ReactMarkdown from "react-markdown";
import CData from '../utils/clientKey'
import emailjs from "emailjs-com";

const currency = "USD";
const style = {"layout":"vertical"};
const CLIENT_ID = CData.CLIENT_ID

export default function App(props) {
    return (
        <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
                <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: props.value,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function (result) {
                            console.log(result);
                            var selectedPrice = typeof window !== 'undefined' ? sessionStorage.getItem("selectedPrice") : null;
                            var PostData = typeof window !== 'undefined' ? sessionStorage.getItem("PostData") : null;
                            var PutData = typeof window !== 'undefined' ? sessionStorage.getItem("PutData") : null;
                            var orderId = result.id;
                            var TransactionID = result.purchase_units[0].payments.captures[0].id;

                            //cmp payment details
                            var MemberData = typeof window !== 'undefined' ? sessionStorage.getItem("Member") : null;
                            var NonMemberData = typeof window !== 'undefined' ? sessionStorage.getItem("NonMember") : null;
                            
                            //cmp payment details
                      
                            //workshop
                            var createWorkshop = typeof window !== 'undefined' ? sessionStorage.getItem("createWorkshop") : null; 

                            function UsernamePasswordGenerator() {
                              var string =
                                '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
                              let password = ''

                              // Find the length of string
                              var len = string.length
                              for (let i = 0; i < 5; i++) {
                                password += string[Math.floor(Math.random() * len)]
                              }
                              return password
                            }    
                            const UserPassword = "username" + UsernamePasswordGenerator()

                            if(PostData == "PostData"){


                              var currentDate = new Date()
                              if(currentDate.getMonth() <=6){
                                var currentYear = currentDate.getFullYear();
                              }
                              else{
                                var currentYear = currentDate.getFullYear()+1;
                              }
                              currentDate.setDate(15)
                              currentDate.setMonth(6)
                              currentDate.setFullYear(currentYear);
                              var expiryDate = currentDate;

                              const newdata = {
                              LastPaymentDate: new Date(), 
                              NextRenewalDate: expiryDate,
                               OrderID: orderId,
                               PaymentAmount: selectedPrice,
                               Status: result.status,
                               TransactionID: TransactionID,
                               FirstName: props.newMember.FirstName,
                               LastName: props.newMember.LastName,
                               IOWALicenseNumber:props.newMember.IowaLicenseNumber,
                               InterepeterCredentials: props.newMember.InterpretingCredentials,
                               City: props.newMember.city,
                               State: props.newMember.state,
                               Zipcode: props.newMember.zipcode,
                               email: props.newMember.emailAddress,
                               Address: props.newMember.Address,
                               UserPhoneNumber: props.newMember.PreferredPhoneNumber,
                               MemberShipCategoryDesired: props.newMember.MembershipCategoryDesired,
                               Opt: props.newMember.opt,
                               InterpreterSearchAddress:props.newMember.Address,
                               InterpreterSearchCity:props.newMember.city,
                               InterpreterSearchState:props.newMember.state,
                               InterpreterSearchZipcode:props.newMember.zipcode,
                               InterpreterSearchEmail:props.newMember.emailAddress,
                               InterpreterSearchPhoneNumber:"",
                               InterpreterSearchBusinessNumber:"",
                               InterpreterSearchVideoNumber:"",
                               

                              username: props.newMember.emailAddress,
                              password: UserPassword,
                            }
                            
                              axios.post(AXIOSLINK +'users', newdata)
                              .then(result => {
                                console.log(result)
                                Swal.fire({
                                  title:' Thank You for Your Payment!',
                                  text: 'Your transaction ID is : ' + TransactionID,
                                  confirmButtonColor: '#00c851',
                                }).then(result1 => {
                                  /* Read more about isConfirmed, isDenied below */
                                  
                                   var templateParams = {
                                    Transaction_ID: TransactionID,
                                    Next_Renewal :  expiryDate,
                                    Email : newdata.email,
                                    firstname: newdata.FirstName,
                                    lastname: newdata.LastName,
                                    address:newdata.Address,
                                    city:newdata.City,
                                    state:newdata.State,
                                    zipcode:newdata.Zipcode,
                                    phonenumber:newdata.UserPhoneNumber,
                                    membershipcategory:newdata.MemberShipCategoryDesired,
                        
                                  }

                                   var admintemp = {
                                    firstname: newdata.FirstName,
                                    lastname: newdata.LastName,
                                    address:newdata.Address,
                                    city:newdata.City,
                                    state:newdata.State,
                                    zipcode:newdata.Zipcode,
                                    emailid:newdata.email,
                                    phonenumber:newdata.UserPhoneNumber,
                                    membershipcategory:newdata.MemberShipCategoryDesired,
                                    transaction_id:TransactionID,
                                    next_Renewal:expiryDate,
                                  }

                                  //for admin
                                  emailjs.send('service_oum2d4o', 'template_dl4lfjl', admintemp, 'user_V2TjOGkvC6BcqaTAwfAjP')
                                    .then(
                                      function(result) {
                                        //console.log('SUCCESS!', result.status, result.text);
                                      },
                                      function(error) {
                                        //console.log('FAILED...', error);
                                      }
                                    )

                                    // for customers
                                  emailjs.send('service_oum2d4o', 'template_uj4xiql', templateParams, 'user_V2TjOGkvC6BcqaTAwfAjP')
                                    .then(
                                      function(result) {
                                        if (result1.isConfirmed) {
                                          window.location.pathname = '/joinrenew'
                                        } 
                                        //console.log('SUCCESS!', result.status, result.text);
                                      },
                                      function(error) {
                                        //console.log('FAILED...', error);
                                      }
                                    )
                                })
                              })
                              .catch(err =>{
                                console.log(err)
                              })
                             
                              
                            }


                              if(PutData == "PutData"){
                                  var value1 = AXIOSLINK + 'users/'
                                  var value2 = props.returningMember.Id
                                  var value3 = value1 + value2

                                  var ExpiryDate = props.returningMember.ExpiryDate;

                                  var TodaysDate = new Date().toUTCString();
                                  var NewTodaysDate = new Date(TodaysDate);

                                  var Expiry = new Date(ExpiryDate).toUTCString(); 
                                  var NewExpiryDate = new Date(Expiry);

                                  var NextExpiryDate = null;

                                  if(NewTodaysDate < NewExpiryDate){
                                    //console.log(1)

                                    var currentDate = new Date()

                                    var currentYear = currentDate.getFullYear()+1;

                                    currentDate.setDate(1)
                                    currentDate.setMonth(6)
                                    currentDate.setFullYear(currentYear);

                                    NextExpiryDate = currentDate
                                  }

                                  if(NewTodaysDate > NewExpiryDate){
                                  //  console.log(2)
                                    var currentDate = new Date()
                                    var currentYear = null

                                    if(currentDate.getMonth() <=6){
                                      currentYear = currentDate.getFullYear();
                                    }
                                    else{
                                       currentYear = currentDate.getFullYear()+1;
                                    }

                                    currentDate.setDate(1)
                                    currentDate.setMonth(6)
                                    currentDate.setFullYear(currentYear);

                                    NextExpiryDate = currentDate
                                  }


                              const Returingdata = {
                               LastPaymentDate: new Date(), 
                               NextRenewalDate: NextExpiryDate,
                               OrderID: orderId,
                               PaymentAmount: selectedPrice,
                               Status: result.status,
                               TransactionID: TransactionID,
                               FirstName: props.returningMember.FirstName,
                               LastName: props.returningMember.LastName,
                               IOWALicenseNumber:props.returningMember.IowaLicenseNumber,
                               InterepeterCredentials: props.returningMember.InterpretingCredentials,
                               City: props.returningMember.city,
                               State: props.returningMember.state,
                               Zipcode: props.returningMember.zipcode,
                               email: props.returningMember.emailAddress,
                               Address: props.returningMember.Address,
                               UserPhoneNumber: props.returningMember.PreferredPhoneNumber,
                               MemberShipCategoryDesired: props.returningMember.MembershipCategoryDesired,
                               Opt: props.returningMember.opt,
                               

                             }
                             console.log(Returingdata)
                               axios.put(value3, Returingdata)
                              .then(result => {
                                console.log(result)
                                Swal.fire({
                                  title:' Thank You for Your Payment!',
                                  text: 'Your transaction ID is : ' + TransactionID,
                                  confirmButtonColor: '#00c851',
                                }).then(result1 => {

                                  var templateParams1 = {
                                    Transaction_ID: TransactionID,
                                    Next_Renewal :  Returingdata.NextRenewalDate,
                                    Email : Returingdata.email,
                                    firstname: Returingdata.FirstName,
                                    lastname: Returingdata.LastName,
                                    address:Returingdata.Address,
                                    city:Returingdata.City,
                                    state:Returingdata.State,
                                    zipcode:Returingdata.Zipcode,
                                    phonenumber:Returingdata.UserPhoneNumber,
                                    membershipcategory:Returingdata.MemberShipCategoryDesired,
                                    
                                   
                                  }

                                  var admintemp1 = {
                                    firstname: Returingdata.FirstName,
                                    lastname: Returingdata.LastName,
                                    address:Returingdata.Address,
                                    city:Returingdata.City,
                                    state:Returingdata.State,
                                    zipcode:Returingdata.Zipcode,
                                    emailid:Returingdata.email,
                                    phonenumber:Returingdata.UserPhoneNumber,
                                    membershipcategory:Returingdata.MemberShipCategoryDesired,
                                    transaction_id:TransactionID,
                                    next_Renewal:Returingdata.NextRenewalDate,
                                  }

                                  //for admin
                                  emailjs.send('service_oum2d4o', 'template_dl4lfjl', admintemp1, 'user_V2TjOGkvC6BcqaTAwfAjP')
                                    .then(
                                      function(result) {
                                        //console.log('SUCCESS!', result.status, result.text);
                                      },
                                      function(error) {
                                        //console.log('FAILED...', error);
                                      }
                                    )

                                  emailjs.send('service_oum2d4o', 'template_uj4xiql', templateParams1, 'user_V2TjOGkvC6BcqaTAwfAjP')
                                    .then(
                                      function(result) {
                                        if (result1.isConfirmed) {
                                          window.location.pathname = '/joinrenew'
                                        }
                                        //console.log('SUCCESS!', result.status, result.text);
                                      },
                                      function(error) {
                                        //console.log('FAILED...', error);
                                      }
                                    )
                                  /* Read more about isConfirmed, isDenied below */
                                   
                                })
                              })
                              .catch(err =>{
                                console.log(err)
                              })
                            }
                            
                            if(MemberData == 'Member'){

                              const Member ={
                              payerName : result.payer.name.given_name,
                              Surname:  result.payer.name.surname,
                              payerEmail : result.payer.email_address,
                              Amountpayed : result.purchase_units[0].amount.value,
                              status : result.status,
                              }

                              fetch(
                                "https://avu55myped.execute-api.us-east-1.amazonaws.com/ISRIDSendEmail",
                                {
                                  mode: "no-cors",
                                  method: "POST",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    FromID : "sanjay20111999@gmail.com",
                                    ToID: "sanjay20111999@gmail.com",
                                    Subjectcontent: "CMP- New Paymenet Received from Member",
                                    Message: "<table><tr><th>Payer Name:</th><td>"+Member.payerName + Member.Surname+
                                    "</td></tr><tr><th>Email Address:</th><td>"+Member.payerEmail +
                                    "</td></tr><tr><th>CMP Category:</th><td>"+ MemberData +
                                    "</td></tr><tr><th>Status:</th><td>"+Member.status +
                                    "</td></tr><tr><th>TransactionID:</th><td>"+TransactionID +
                                    "</td></tr><tr><th>OrderID:</th><td>"+orderId+
                                    "</td></tr></table>",
                                  }),
                                }
                              ).then(response => {
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Thank you',
                                    text: 'Payment Successful',
                                    confirmButtonText:'Okey!',
                                    confirmButtonColor: '#00c851',
                                  }).then(result1 => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result1.isConfirmed) {
                                      window.location.pathname = '/CMP'
                                    } 
                                  })
                                  
                                },
                                
                              )

                            }

                            if(NonMemberData == 'NonMember'){

                              const NonMember ={
                              payerName : result.payer.name.given_name,
                              Surname:  result.payer.name.surname,
                              payerEmail : result.payer.email_address,
                              Amountpayed : result.purchase_units[0].amount.value,
                              status : result.status,
                              }

                              fetch(
                                "https://avu55myped.execute-api.us-east-1.amazonaws.com/ISRIDSendEmail",
                                {
                                  mode: "no-cors",
                                  method: "POST",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    FromID : "sanjay20111999@gmail.com",
                                    ToID: "sanjay20111999@gmail.com",
                                    Subjectcontent: "CMP- New Paymenet Received from Member",
                                    Message: "<table><tr><th>Payer Name:</th><td>"+NonMember.payerName + NonMember.Surname+
                                    "</td></tr><tr><th>Email Address:</th><td>"+NonMember.payerEmail +
                                    "</td></tr><tr><th>CMP Category:</th><td>"+ NonMemberData +
                                    "</td></tr><tr><th>Status:</th><td>"+NonMember.status +
                                    "</td></tr><tr><th>TransactionID:</th><td>"+TransactionID +
                                    "</td></tr><tr><th>OrderID:</th><td>"+orderId+
                                    "</td></tr></table>",
                                  }),
                                }
                              ).then(response => {
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Thank you',
                                    text: 'Payment Successful',
                                    confirmButtonText:'Okey!',
                                    confirmButtonColor: '#00c851',
                                  }).then(result1 => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result1.isConfirmed) {
                                      window.location.pathname = '/CMP'
                                    } 
                                  })
                                  
                                },
                                
                              )

                            }
                      
                            if(createWorkshop == 'true'){
                              const data ={
                                firstname:props.createWorkshop.firstname,
                                lastname:props.createWorkshop.lastname,
                                emailaddress:props.createWorkshop.emailaddress,
                                mailedaddress:props.createWorkshop.mailedaddress,
                                transactionid:TransactionID,
                                price:props.value
                              }

                              axios.post(AXIOSLINK +'work-shop-details',data)
                              .then(result => {
                                console.log(result)
                                Swal.fire({
                                  title:' Thank You for Your Payment!',
                                  text: 'Your transaction ID is : ' + TransactionID,
                                  confirmButtonColor: '#00c851',
                                }).then(result1 => {
                                  if (result1.isConfirmed) {
                                    window.location.pathname = '/home'
                                  } 
                                })
                              })
                            }


                            typeof window !== 'undefined' ? sessionStorage.clear() : null
                            //window.location.pathname="/"
                                });
                              }}
                     onCancel={function (data) {
                     // Show a cancel page, or return to cart
                    Swal.fire({
                    title: 'Do you want to cancel the payment?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#00c851',
                  }).then(result => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      //window.location.pathname = '/'
                    } 
                  })
                 }}
                
            />
        </PayPalScriptProvider>
    );

    
}
